    <app-app-sub-header class="top-row d-flex justify-content-between" (onRefreshClicked)="resetListSettings()" (searchClicked)="searchData($event)" [isSearchable]="isSearchable" [pageTitle]="pageTitle" [showCreateButton]="showCreateButton" [EditId]="EditId"></app-app-sub-header>
<div class="bottom-table"> 
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" style="width:16%" (mouseenter)="mouseEnter('name')" (mouseleave)="mouseLeave('name')">
          Client Name
          <span *ngIf="currentSortedColumn != 'name' && mouseOnColumnName == 'name'" (click)="getClientList({sort_field:'name',sort_method:'desc',column_name:'name'})" class="sortbyspanr"
              [hidden]="!iconVisible ">
              <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
            </span>
            <span class="sortbyspanr" *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleDesc" (click)="getClientList({sort_field:'name',sort_method:'asc',column_name:'name'})">
              <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
            </span>
            <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleAsc" (click)="getClientList({sort_field:'name',sort_method:'desc',column_name:'name'})">
              <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
            </span>
        </th>
        <th scope="col" style="width:12%" (mouseenter)="mouseEnter('type')" (mouseleave)="mouseLeave('type')">
          Client Type
          <span *ngIf="currentSortedColumn != 'type' && mouseOnColumnName == 'type'"
            (click)="getClientList({sort_field:'type',sort_method:'desc',column_name:'type'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'type'" [hidden]="!iconVisibleDesc"
            (click)="getClientList({sort_field:'type',sort_method:'asc',column_name:'type'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'type'" [hidden]="!iconVisibleAsc"
            (click)="getClientList({sort_field:'type',sort_method:'desc',column_name:'type'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th scope="col" style="width:12%" (mouseenter)="mouseEnter('client_status_id')"
          (mouseleave)="mouseLeave('client_status_id')">
          Stage
          <span *ngIf="currentSortedColumn != 'client_status_id' && mouseOnColumnName == 'client_status_id'"
            (click)="getClientList({sort_field:'client_status_id',sort_method:'desc',column_name:'client_status_id'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'client_status_id'" [hidden]="!iconVisibleDesc"
            (click)="getClientList({sort_field:'client_status_id',sort_method:'asc',column_name:'client_status_id'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'client_status_id'" [hidden]="!iconVisibleAsc"
            (click)="getClientList({sort_field:'client_status_id',sort_method:'desc',column_name:'client_status_id'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <!-- Probability -->
        <th scope="col" style="width:10%" (mouseenter)="mouseEnter('client_probability_status_id')"
          (mouseleave)="mouseLeave('client_probability_status_id')">
          Probability
          <span
            *ngIf="currentSortedColumn != 'client_probability_status_id' && mouseOnColumnName == 'client_probability_status_id'"
            (click)="getClientList({sort_field:'client_probability_status_id',sort_method:'desc',column_name:'client_probability_status_id'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'client_probability_status_id'" [hidden]="!iconVisibleDesc"
            (click)="getClientList({sort_field:'client_probability_status_id',sort_method:'asc',column_name:'client_probability_status_id'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'client_probability_status_id'" [hidden]="!iconVisibleAsc"
            (click)="getClientList({sort_field:'client_probability_status_id',sort_method:'desc',column_name:'client_probability_status_id'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th scope="col" style="width:12%" (mouseenter)="mouseEnter('primary_contact')"
          (mouseleave)="mouseLeave('primary_contact')">
          Contact Person
          <span *ngIf="currentSortedColumn != 'primary_contact' && mouseOnColumnName == 'primary_contact'"
            (click)="getClientList({sort_field:'primary_contact',sort_method:'desc',column_name:'primary_contact'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'primary_contact'" [hidden]="!iconVisibleDesc"
            (click)="getClientList({sort_field:'primary_contact',sort_method:'asc',column_name:'primary_contact'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'primary_contact'" [hidden]="!iconVisibleAsc"
            (click)="getClientList({sort_field:'primary_contact',sort_method:'desc',column_name:'primary_contact'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        
        <th scope="col" style="width:12%" (mouseenter)="mouseEnter('contact_number')"
          (mouseleave)="mouseLeave('contact_number')">
          Contact Number
          <span *ngIf="currentSortedColumn != 'contact_number' && mouseOnColumnName == 'contact_number'"
            (click)="getClientList({sort_field:'contact_number',sort_method:'desc',column_name:'contact_number'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'contact_number'" [hidden]="!iconVisibleDesc"
            (click)="getClientList({sort_field:'contact_number',sort_method:'asc',column_name:'contact_number'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'contact_number'" [hidden]="!iconVisibleAsc"
            (click)="getClientList({sort_field:'contact_number',sort_method:'desc',column_name:'contact_number'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        
        <th scope="col" style="width:12%" (mouseenter)="mouseEnter('contact_email')" (mouseleave)="mouseLeave('contact_email')">
          Email
          <span *ngIf="currentSortedColumn != 'contact_email' && mouseOnColumnName == 'contact_email'"
            (click)="getClientList({sort_field:'contact_email',sort_method:'desc',column_name:'contact_email'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'contact_email'" [hidden]="!iconVisibleDesc"
            (click)="getClientList({sort_field:'contact_email',sort_method:'asc',column_name:'contact_email'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'contact_email'" [hidden]="!iconVisibleAsc"
            (click)="getClientList({sort_field:'contact_email',sort_method:'desc',column_name:'contact_email'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>

      


       
        <th scope="col" style="width:5%" >
          Action
        </th>
      </tr>
    </thead>
    <tbody>
     
    
      <tr *ngFor="let cl of clientList;">
        <td (click)="open(UpdateClient,cl.id)"> <span class="name"> {{cl.name}}</span> </td>
        <td (click)="open(UpdateClient,cl.id)">{{cl.type}}</td>
        <td (click)="open(UpdateClient,cl.id)"><span> {{cl?.status?.name?cl.status.name:"---"}} </span> </td>            
        <td (click)="open(UpdateClient,cl.id)" style="text-align: left;">
          <span> {{cl?.probability_status?.name?cl.probability_status.name:"---"}} </span> 
        </td>            
       
       

        <td *ngIf="cl.candidate_id; else noLink" (click)="showCandidate(cl.candidate_id)">
          <a style="cursor: pointer; color: blue;">
            {{ (cl.primary_contact !== null && cl.primary_contact !== 'null') ? cl.primary_contact : '---' }}
          </a>
        </td>
        <ng-template #noLink>
          <td>
            {{ (cl.primary_contact !== null && cl.primary_contact !== 'null') ? cl.primary_contact : '---' }}
          </td>
        </ng-template>
        
        
        
        
        <td (click)="open(UpdateClient,cl.id)">{{(cl.contact_number || cl.contact_number === "null")?cl.contact_number :"---"}}</td>
        <td style="text-align:left;">
          <a style="text-decoration: none;" href="mailto:{{cl.contact_email}}">
          {{cl.contact_email?cl.contact_email:"---"}}
        </a>
        </td>
       


        <td ><div style="cursor: pointer;">
          <a (click)="open(UpdateClient,cl.id)"><em class="bi bi-pencil-square me-3" title="Edit"></em></a>
          <a (click)="openDeleteModal(content,cl.id,cl)"><em class="bi bi-trash" title="Delete"></em></a>
        </div></td>
      </tr>
    </tbody>
  </table>
</div>
<div class="pagination-main">
   
    <label for="totalRows" class="list-totalrecords">Total Records: {{totalRows}}</label>
    <ul *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination justify-content-end m-0">
      
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
          <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link page-show"> <<</a>
          <ng-template #elseFirst>
              <a (click)="setPage(1)" class="page-link"><<</a>
          </ng-template>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
          <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link page-show"> <</a>
          <ng-template #elsePrevious>
              <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
          </ng-template>
      </li>
    
      <li class="page-item ">
        <a class="page-link">{{pager.currentPage}}</a>
    </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
          <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link page-show">></a>
          <ng-template #elseNext>
              <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
          </ng-template>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
          <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link page-show">>></a>
          <ng-template #elseLast>
              <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
          </ng-template>
      </li>
  </ul>
</div>

<ng-template #UpdateClient let-modal style="width: 1000px;">
  <div style="width: 1000px;" class="new">
    <div class="row">
      <div class="col-lg-12">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')  ; this.uploadsubmitted=false ;  this.submitted = false ; resetBackendClienterror()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
    </div>
    <div class="row">
   <div class="col-lg-6">
  
    <form [formGroup]="CreateClientForm">  
      <div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientError">{{createClientError}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createClientErrorName">{{createClientErrorName}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorIndustry">{{createClientErrorIndustry}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createClientErrorContactnumber">{{createClientErrorContactnumber}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorEmail">{{createClientErrorEmail}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createClientErrorAddress">{{createClientErrorAddress}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorPostal">{{createClientErrorPostal}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorState">{{createClientErrorState}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorWebsite">{{createClientErrorWebsite}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createClientErrorDescription">{{createClientErrorDescription}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createClientErrorPrimarycontact">{{createClientErrorPrimarycontact}}</div>

      </div>
    <div class="modal-body scrollsearch">


      <div class="mb-3">
        <label for="">Client Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter client name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && f['name'] && f['name'].errors && f['name'].errors['required']">
          Client Name is required.
          </div>
      </div>

     

      <div class="mb-3">
        <label for="">Account Owner/Originator</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <ng-select [items]="userList" class="form-control form-select" bindLabel="name" bindValue="id"
          formControlName="user_id" placeholder="Select Account Owner/Originator" (clear)="getUsersList()" 
          (search)="getUsersList($event)">
        </ng-select>
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && f['user_id'] && f['user_id'].errors && f['user_id'].errors['required']">
          Account Owner/Originator is required.
        </div>
      </div>


      <div class="row mb-3">
        <div class="col-6">
          <label for="">Industry</label>
          <input type="text" placeholder="Enter industry" formControlName="industry" class="form-control">
        </div>
      
        <div class="col-6">
          <label for="">No. of Searches</label>
          <input type="text" formControlName="no_of_searches" class="form-control">
        </div>
      
      
      </div>


      <div class="row mb-3">

        <div class="col-6">
          <label for="">Client Stage</label>
          <select name="" id="" class="form-select" formControlName="client_status_id">
            <option value=0>Select Client Stage</option>
            <option value="{{cs.id}}" *ngFor="let cs of clientStatusList">{{cs.name}}</option>
          </select>
        </div>
      
        <div class="col-6">
          <label for="">Client Probability</label>
          <select name="" id="" class="form-select" formControlName="client_probability_status_id">
            <option value=0>Select Probability</option>
            <option *ngFor="let status of ClientProbabiltylist" [value]="status.id">
              {{status.name}}</option>
          </select>
        </div>

      </div>

      <div class="form-check pt-1 mb-3">
        <input class="form-check-input" title="is_referenceable" type="checkbox" value=""
          formControlName="is_referenceable">
        <label class="form-check-label" title="is_referenceable" for="flexCheckDefault">
          Referenceable Client
        </label>
      </div>


      <div class="mb-3">
      
        <div class="row">
          <label for="" style="margin:0px">Primary Contact </label>
          <div style="margin-left:46%;font-size: x-small;">
            OR
          </div>
          <div class="col-6">
      
            <ng-select [items]="candidateList" (change)="populateEmail($event)" (keyup)="populateEmail($event)"
              class="form-control form-select" bindLabel="full_name" bindValue="full_name" formControlName="primary_contact"
              placeholder="Pick from Candidates">
            </ng-select>
      
         
      
          </div>
        
          <div class="col-6">
      
            <input type="text" class="form-control" formControlName="primary_contact_static" placeholder="Enter Contact Name">
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-6">
        
          <div class="mb-3">
            <label for="">Phone</label>
            <input type="text"  maxlength="14"  placeholder="Enter contact number"  formControlName="contact_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['contact_number'].errors }">
            <div style="color: red; font-size: 14px;" *ngIf="f['contact_number'] && f['contact_number'].errors && f['contact_number'].errors['pattern']">
              Please enter valid phone number.
              </div> 
          </div>

          <div class="mb-3" hidden>
            <label for="">Candidate Id</label>
            <input type="text" formControlName="candidate_id" class="form-control" >
          </div>

          
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="">Email</label>
            <input type="text" placeholder="Enter email " formControlName="contact_email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['contact_email'].errors }" >
            <div style="color: red; font-size: 14px;" *ngIf="f['contact_email'] && f['contact_email'].errors && f['contact_email'].errors['pattern']">
              Please enter valid Email address.
              </div> 
            
          </div>
    
        </div>       
      </div>
          <div class="mb-3">
            <label for="">Street address</label>
            <input type="text" placeholder="Enter street address" formControlName="street_address" class="form-control" >
         
          </div>
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="">Postal code</label>
                <input type="text" placeholder="Enter postal code" formControlName="postal_code" class="form-control" >
             
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="">City</label>
                <input type="text" placeholder="Enter city" formControlName="city" class="form-control"[ngClass]="{ 'is-invalid': submitted && f['city'].errors }" >
              </div>
            </div>
          </div>
        
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="">State</label>
            <ng-select [items]="stateList" class="form-control form-select"
            bindLabel="name" bindValue="name"
            formControlName="state" placeholder="Select state" >
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <div class="mb-3">
            <label for="">Website</label>
            <input type="text" placeholder="Enter website" formControlName="website" class="form-control" >
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="">Description</label>
        <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control" ></textarea>
      </div>

  
    
  
  
    </div>
    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ;this.uploadsubmitted=false  ; this.submitted = false ; resetBackendClienterror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn ms-" (click)="UpdateClients()" *ngIf="!projectapicall">Save</button>
    
    </div>
  
  </form>
  </div>

  <div class="col-lg-1" style="width: 0.33333%;">
    <div class="vl"></div>
  </div>
  
  <div class="col-lg-5" style="padding: 0px 0px 0px 10px;">
    <div class="modal-header" style="border-bottom: unset !important;padding-bottom: 0px;">
      <div style="display: flex;">
        <h5 class="modal-title" id="modal-basic-title"><strong>Documents</strong></h5>
      </div>
  
    </div>
    <form [formGroup]="CreateClientForm">

      <div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
          *ngIf="CreatDocumentsErrorDocuments">{{CreatDocumentsErrorDocuments}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
          *ngIf="createClientErrorWeblinkone">{{createClientErrorWeblinkone}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
          *ngIf="createClientErrorWeblinktwo">{{createClientErrorWeblinktwo}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
          *ngIf="createClientErrorWeblinkthree">{{createClientErrorWeblinkthree}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createClientErrorLogo">
          {{createClientErrorLogo}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
          *ngIf="CreatDocumentsErrorDocumentsname">{{CreatDocumentsErrorDocumentsname}}</div>
      </div>

      <div class="modal-body ">

        <div class="row">
          <div class="col-8">
            <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Document is required"
              max-file-size="10000000" formControlName="document" class="form-control" (change)="attachFiles($event)">
          </div>
          <div class="col-4">
            <button type="button" class="blue-btn ms-" (click)="UploadDocuments()" style="float: right;"
              *ngIf="!projectapicall2 && showUploadFileButton">Upload </button>
          </div>
        </div>

        <div class="row" style="margin-top: 15px;">
          <div class="col-lg-12">
  
            <div *ngIf="projectapicall">{{fileName}}</div>
            <div class="row">
              <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldoc"
                aria-labelledby="panelsStayOpen-headingsix">
                <div class="accordion-body" *ngFor="let document of documentData ">
                  <div class="experience d-flex justify-content-between">
                    <div class="experience-left col-lg-10">
                      <h6 class="modal-title" id="modal-basic-title">
                        <a href="{{document?.path}}" target="_blank"
                          style="color: gray;text-decoration: unset !important;font-size: 14px;"> {{document?.name}}</a>
                      </h6>
                    </div>
                    <div class="inner-edit col-lg-2 " style="text-align: right;">
                      <a href="javascript:void(0)" (click)="deleteDocuments(document.id)"> <em class="bi bi-trash"
                          title="Delete" style="color: black;"></em></a>
                    </div>
                  </div>
                  <hr style="margin-top: 2px;">
                </div>
              </div>
              <div class="accordion-body" *ngIf="documentData?.length==0" style="margin-left: 20px;">No Documents added
              </div>
  
              <div style="margin-top: 10px;">
                <div class="spinner-border" *ngIf="projectapicall2">
                  <span class="visually-hidden">Uploading...</span>
                </div>
  
  
              </div>
            </div>
          </div>
  
        </div>
  
        <div class="row" style="margin-top: 20px;">
          <div class="row" style="display: flex;justify-content: space-between;">
            <label for="">Web link 1</label>
            <label for="" *ngIf="showexternallink1 && !editexternallink1"><span
                (click)="editexternallink1 =true; showexternallink1=false;">{{weblink1| slice:0:40
                }}{{(weblink1.length>40?'...
                ':' ')}}</span><a href="{{weblink1}}" target="_blank" title="Open web link"><em
                  class="fa fa-external-link" aria-hidden="true"></em> </a></label>
            <input form="" *ngIf="!showexternallink1 && editexternallink1" type="text" placeholder="Enter URL"
              formControlName="web_link_one" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['web_link_one'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="f['web_link_one'] && f['web_link_one'].errors && f['web_link_one'].errors['pattern']">
              Please enter a valid URL. (i.e. http://www.google.com)
            </div>
  
  
          </div>
          <div class="row" style="display: flex;justify-content: space-between;">
            <label for="">Web link 2</label>
            <label for="" *ngIf="showexternallink2 && !editexternallink2"><span
                (click)="editexternallink2 =true; showexternallink2=false;">{{weblink2| slice:0:40
                }}{{(weblink2.length>40?'...
                ':' ')}} </span><a href="{{weblink2}}" target="_blank" title="Open web link"> <em
                  class="fa fa-external-link" aria-hidden="true"></em> </a></label>
  
            <input form="" *ngIf="!showexternallink2 && editexternallink2" type="text" placeholder="Enter URL"
              formControlName="web_link_two" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['web_link_two'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="f['web_link_two'] && f['web_link_two'].errors && f['web_link_two'].errors['pattern']">
              Please enter a valid URL. (i.e. http://www.google.com)
            </div>
          </div>
          <div class="row" style="display: flex;justify-content: space-between;">
  
            <label for="" *ngIf="!showexternallink3">Web link 3</label>
            <label for="" *ngIf="showexternallink3 && !editexternallink3"><span
                (click)="editexternallink3 =true; showexternallink3=false;">{{weblink3| slice:0:40
                }}{{(weblink3.length>40?'...
                ':' ')}}</span> <a href="{{weblink3}}" target="_blank" title="Open web link"><em
                  class="fa fa-external-link" aria-hidden="true"></em> </a></label>
  
            <input *ngIf="!showexternallink3 && editexternallink3" type="text" placeholder="Enter URL"
              formControlName="web_link_three" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['web_link_three'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="f['web_link_three'] && f['web_link_three'].errors && f['web_link_three'].errors['pattern']">
              Please enter a valid URL. (i.e. http://www.google.com)
            </div>
          </div>
        </div>
  
        <div class="row" style="margin-top: 20px;">
          <div>
            <h5 class="modal-title" id="modal-basic-title" style="float: left;"><strong>Client Logo</strong></h5>
            <button type="button" class="blue-btn" (click)="selectFile.click()" *ngIf="logoBox"
              style="font-size: 14px !important;float: right;">Change</button>
          </div>
          <input type="file" id="file-input" style="display: none;" accept="image/*" multiple #selectFile
            max-file-size="1024" formControlName="logo" class="form-control" (change)="attachLogoFiles($event)" />
        </div>

      

        <div class="row" style="margin-top: 10px;">
          <div *ngIf="rectangleBox" id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldocument"
            aria-labelledby="panelsStayOpen-headingsix">
            <label for="file-input" class="accordion-body clientlogobg file full-width-label">
              <span>Choose File</span>
              <input type="file" id="file-input" style="display: none;" accept="image/*" multiple #selectFile
                max-file-size="1024" formControlName="logo" class="form-control" (change)="attachLogoFiles($event)" />
            </label>
          </div>
          <div class="col-lg-12 row" style="margin-top: 10px;" *ngIf="logoBox">
            <div class="col-12" style="padding-right: 0px;">
              <img [src]="url" *ngIf="url !== null" height="120" class="clientlogobgimg img-fluid" alt="">
            </div>
          </div>
        </div>
        

      </div>
    </form>
  </div>

</div>
  </div>
</ng-template>

<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Delete Client</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
        <p>
          Are you sure you want to delete {{selectDeletedClient}} ?
        </p>
        <div class="alert">
          <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
          <strong>All the Searches of this client will be also deleted</strong>
        </div>
			</div>
		</form>
	</div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary cancelbutton" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger dangerbtn" (click)="modal.close('Ok click')">Delete</button>
  </div>
</ng-template>



